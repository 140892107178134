function getConf(vm) {
  const { conf } = vm.$options;
  if (conf) {
    return typeof conf === 'function'
      ? conf.call(vm)
      : conf;
  }
}

const serverTitleMixin = {
  created() {
    const conf = getConf(this);
    if (conf) {
      if (conf.name) {
        this.$ssrContext.title = conf.name;
      }
      let meta = '';
      if (conf.desction) {
        meta += `<meta name="description" content="${conf.desction}">`;
      }
      if (conf.keywords) {
        meta += `<meta name="keywords" content="${conf.keywords}">`;
      }
      this.$ssrContext.meta = meta;
    }
  }
};

const clientTitleMixin = {
  mounted() {
    const conf = getConf(this);
    if (conf) {
      if (conf.name) {
        document.title = conf.name;
      }
    }
  }
};

// 可以通过 `webpack.DefinePlugin` 注入 `VUE_ENV`
export default process.env.VUE_APP_ENV === 'server'
  ? serverTitleMixin
  : clientTitleMixin;
